@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(246, 245, 248);
}

section {
  max-width: 1200px;
  margin: 3rem auto;
  padding: 1rem;
}

section > h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}
section > h2 {
  font-size: 1.4rem;
  margin-bottom: 0;
  font-weight: 600;
}
section > p {
  margin-bottom: 2rem;
  word-spacing: .8rem;
  line-height: 1.5rem;
}

header nav a {
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}

header nav a.active {
  background-color: rgb(81, 204, 47);
  color: #111;
}

section nav a {
  text-decoration: none;
  padding: 10px 16px;
  border-radius: 4px;
  border: 2px solid rgb(218, 234, 248);
  text-align: center;
  font-weight: 600;
  width: 170px;
}

section nav a:hover {
  border: 2px solid green;
}

section nav a.active {
 background-color: rgb(81, 204, 47);;
  color: #111;
}

.careers > a {
  display: block;
  background: rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 4px;
  margin: 20px 0;
  font-size: 1.2rem;
  text-decoration: none;
}

.careers > a:hover p:first-child {
  color: #666;
  transition: all 0.3s ease-in-out;
}

/* .careers > p span,
.careers > h1 span {
  font-weight: 700;
} */

.careers > a p:last-child {
  font-size: .9rem;
}

/* BREADCRUMBS CSS. BUT TAILWIND USED INSTEAD*/
.bread {
  max-width: 1200px;
  margin: 6rem auto 2rem;
  padding-inline: 1rem;
}

.bread > * {
  display: inline-block;
  margin-right: 10px;
}

.bread .crumb:after {
  content: '>';
  margin-left: 10px;
}

.bread .crumb:last-child:after {
  display: none;
}

.bread .crumb a {
  color: rgb(109, 15, 15);
}

.crumb {
  display: inline-block;
  font-size: 1.2rem;
  /* text-decoration: underline; */
  text-transform: capitalize;
}

/* About Background */

.home-background {
  background-image: url('./images/Cover5.jpeg');
  background-size:cover;
  background-repeat: no-repeat;
}

.background {
  background-image: url('./images/Cover5.jpeg');
  margin-top: -80px;
  margin-bottom: -52px;
}
.background > p {
  font-weight: bolder;
}

/* Toolkit*/
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}